import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import AngleRight from '@assets/angle-right.svg'

import {
  container
} from '@styles/navigation-list.module.scss'

const AodaPage = () => {
  return (
    <Layout pageTitle="A.O.D.A.">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/aoda_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>ABOUT</h2>
                <h1>A.O.D.A.</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row>
            <Col>
              <div>
                <h3>PRESIDENT'S MESSAGE</h3>
              </div>
              <div>
                <p>
                  While fulfilling our mission, all Wessuc staff are committed to providing accessibility and equitable customer service to all of our customers. We strive to design and operate our business so that every person benefits from the same services regardless of any disability they may have.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row>
            <Col>
              <h3>Statement of Commitment</h3>
              <p>
                Wessuc Inc. is committed to providing an inclusive, accessible, and welcoming environment for all persons with disabilities in a way that respects the independence and dignity of individuals with disabilities and in a manner which takes into account the person's disability and embodies the principles of integration and equal opportunity.
              </p>
              <p>
                Wessuc Inc. is committed to continuous improvement in becoming a barrier free environment and meeting the requirements of all existing legislation and its own policies and goals related to identifying, removing and preventing barriers to people with disabilities that might interfere with their ability to interact with Wessuc Inc.
              </p>
              <p>
                Wessuc Inc. ensures that all persons within its’ community are aware of their rights and responsibilities to foster an accessible and inclusive environment with and for persons with disabilities.
              </p>
              <p>
                Wessuc Inc. is committed to, and will strive to ensure that, the Accessibility for Ontarian's with Disabilities Act (AODA), 2005, its regulations, standards and all other relevant legislation concerning accessibility, are rigorously observed in a timely fashion.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl">
          <Row>
            <Col>
              <div className="section">
                <div className={container}>
                  <Link to="/aoda/multi-year-plan">Multi Year Accessibility Plan <AngleRight /></Link>
                  <a href="https://wordpress.wessuc.com/wp-content/uploads/2019/11/Wessuc-Inc-Accessibility-Policy.pdf" target="_blank">Accessibility Policy and Program <AngleRight /></a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default AodaPage